import React, { Component , Suspense } from 'react';
import './App.css';
 
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import {ProtectedRoute} from './Components/helper/protected.route.js';

import LoadingOverlay from 'react-loading-overlay';


import Loading from 'react-fullscreen-loading';



import ContentLoader, { Facebook } from 'react-content-loader'

import Loader from 'react-loader-spinner'


const LoginComponent = React.lazy(() => import('./Components/Login/Login'));
const RegisterComponent = React.lazy(() => import('./Components/Register/Register'));
const ForgotPasswordComponent = React.lazy(() => import('./Components/ForgotPassword/forgotPassword'));
const LandingComponent = React.lazy(() => import('./Components/Landing/landing'));
const ProfileComponent = React.lazy(() => import('./Components/Profile/Profile'));
const OTPLoginComponent = React.lazy(() => import('./Components/Otp/OTPLogin'));
const OTPSignupComponent = React.lazy(() => import('./Components/Otp/OTPSignup'));
const LogoutComponent = React.lazy(() => import('./Components/Logout/logout'));
const ResetPasswordComponent = React.lazy(() => import('./Components/ResetPassword/resetPassword'));
const LinkExpiredComponent = React.lazy(() => import('./Components/LinkExpired/linkExpired'));
const PasswordUpdatedComponent = React.lazy(() => import('./Components/PasswordUpdated/PasswordUpdated'));
const ProfileListComponent = React.lazy(() => import('./Components/ProfileList/ProfileList'));
const ProfileDetailComponent = React.lazy(() => import('./Components/ProfileDetail/profileDetail'));
const ManagerDashboard = React.lazy(() => import('./Components/ManagerDashboard/ManagerDashboard'));
const LeadAssign = React.lazy(() => import('./Components/LeadAssign/LeadAssign'));


let options = {
  lines: 13,
  length: 20,
  width: 10,
  radius: 30,
  scale: 1.00,
  corners: 1,
  color: '#000',
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: '50%',
  left: '50%',
  shadow: false,
  hwaccel: false,
  position: 'absolute'
};


class App extends Component {
  render() {
    return (
      <Router>
      <Suspense fallback={  
        //   <div>
        //     <Loading loading background="#f5f5f5" loaderColor="#3498db" />
        //  </div>
            <div>
              Loading...
            </div>
      }>
        <Switch>
            <Route exact path="/">
              <LoginComponent />
            </Route>
            <Route exact path="/login">
              <LoginComponent />
            </Route>
            <Route exact path="/register">
              <RegisterComponent />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPasswordComponent />
            </Route>
            <Route exact path="/reset-password">
                <ResetPasswordComponent />
            </Route>
            <Route exact path="/link-expired">
                <LinkExpiredComponent />
            </Route>
            <ProtectedRoute path='/landing' component={LandingComponent}/>
            <ProtectedRoute path='/profile/:cust_id/:lead_id/:customer_ref/' component={ProfileComponent}/>
            <Route exact path="/loginotp">
              <OTPLoginComponent />
            </Route>
            <Route exact path="/signupotp">
              <OTPSignupComponent />
            </Route>
            <Route exact path='/password-updated'>
              <PasswordUpdatedComponent />
            </Route>
            <Route exact path="/logout" component={LogoutComponent} />
            <ProtectedRoute path='/profile-list' component={ProfileListComponent}/>
            <ProtectedRoute path='/profile-detail' component={ProfileDetailComponent}/>
            <ProtectedRoute path='/manager-dashboard' component={ManagerDashboard}/>
            <ProtectedRoute path='/lead-assign' component={LeadAssign}/>
            <Route path="*">
              <div>Page Not Found</div>
            </Route>
        </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
