import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';

import 'bootstrap/dist/js/bootstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


ReactDOM.render(
  <App />,
  document.getElementById('root')
);
